import { getDefaultPreviewAppTypeList } from "@uiview/views/PreviewApp/const";
import { GROUPID_REG } from "@views/thinking-layout-editor/constants";
import { ChoosingFormat, chooseFormatStore } from "@uikit/store/ChooseFormatStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { getIt } from "@uikit/getIt";
import { SwitcherModeType, NavBarStore } from "../navBar/NavBarStore";
import { PreviewAppValueGenUtil } from "../PreviewApp/PreviewAppValueGenUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreateProjectService } from "@uikit/service/CreateProjectService";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";
import { AddNode } from "@uikit/service/AddNode";
import { PostMessageUtil } from "imagica-corekit/dist/cases/util/postMessageUtil";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { AuthStore } from "imagica-corekit/dist/base/store/AuthStore";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { FotNavigateController } from "@uikit/service/FotNavigateController";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import func from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

interface ChoosingFormatProps {
  creatorNodesStore: CreatorNodesStore;
  openPreviewPage: () => void;
  setInputId: (val: any) => void;
  previewAppStore: PreviewAppStore;
  creatorPreviewService: CreatorPreviewService;
  createProjectService: CreateProjectService;
  homeHandleFieldMethods: HomeHandleFieldMethods;
}
const navBarStore = getIt(NavBarStore);
const addNode = getIt(AddNode);
export class ChoosingFormatBloc {
  creatorNodesStore: CreatorNodesStore;
  openPreviewPage: () => void;
  setInputId: (val: any) => void;
  previewAppStore: PreviewAppStore;
  creatorPreviewService: CreatorPreviewService;
  createProjectService: CreateProjectService;
  homeHandleFieldMethods: HomeHandleFieldMethods;

  brainClient = getIt(BrainClient);
  authStore = getIt(AuthStore);

  constructor(props: ChoosingFormatProps) {
    const {
      creatorNodesStore,
      openPreviewPage,
      setInputId,
      previewAppStore,
      homeHandleFieldMethods,
      creatorPreviewService,
      createProjectService,
    } = props;
    this.creatorNodesStore = creatorNodesStore;
    this.openPreviewPage = openPreviewPage;
    this.previewAppStore = previewAppStore;
    this.homeHandleFieldMethods = homeHandleFieldMethods;
    this.creatorPreviewService = creatorPreviewService;
    this.setInputId = setInputId;
    this.createProjectService = createProjectService;
  }

  /**
   * 第一次运行边时，自动添加节点到preview
   * 1、AI App：添加input和output节点到preview
   * 2、Static WebSite：添加output节点到preview
   */
  addNodeToPreview(edgeId: string): void {
    switch (chooseFormatStore.state.chooseFormat) {
      case ChoosingFormat.AIAPP:
        this.aiAppAddNodeToPreview(edgeId);
        break;
      case ChoosingFormat.STATICWEBSITE:
        this.staticWebSiteAddNodeToPreview(edgeId);
        break;
      case ChoosingFormat.AICHATAGENT:
        this.aiChatAddNodeToPreview(edgeId);
        break;
      default:
        break;
    }
  }

  /**
   * 点击AI App
   * 1、打开preview页
   * 2、自动创建一个AI App本地数据
   */
  createAiApp(): void {
    this.createProjectService.initNewProjectV2();
    this.openPreviewPage();
    chooseFormatStore.setChooseFormat(ChoosingFormat.AIAPP);
    this.previewAppStore.addPreview({ format: getDefaultPreviewAppTypeList()[0] });
  }

  /**
   * 点击Static WebSite
   * 1、打开preview页
   * 2、自动创建一个Static WebSite本地数据
   */
  createStaticWebSite(): void {
    this.createProjectService.initNewProjectV2();
    this.openPreviewPage();
    chooseFormatStore.setChooseFormat(ChoosingFormat.STATICWEBSITE);
    this.previewAppStore.addPreview({ format: getDefaultPreviewAppTypeList()[1] });
  }

  createThankingCanvas(): void {
    chooseFormatStore.setChooseFormat(ChoosingFormat.THINKINGCANVAS);
    getIt(ChatOnboardingStore).setChatOnboardingState({ selectTemplateInModal: ChoosingFormat.THINKINGCANVAS });
    navBarStore.setSwtichMode(SwitcherModeType.canvas);
    this.createProjectService.initNewProjectV2();
  }

  createAiChat(event: any): void {
    chooseFormatStore.setChooseFormat(ChoosingFormat.AICHATAGENT);
    // 创建一个uploadFile node
    this.openPreviewPage();
    const option = getDefaultPreviewAppTypeList()[2];
    this.previewAppStore.addPreview({
      format: option,
      uiData: PreviewAppValueGenUtil.genPreviewAppTitleByFormat(option),
    });
    addNode.addNodeV2(event, { chatAi: true, displayType: "uploadFile" });
  }

  async createAutoImagicaV2(): Promise<void> {
    const homeStore = getIt(HomeStore);
    const featureTags = homeStore.state.featureTags;
    if (featureTags.disableImagicaBeta) {
      chooseFormatStore.setChooseFormat(ChoosingFormat.AUTOIMAGICA);
      getIt(ChatOnboardingStore).setChatOnboardingState({ selectTemplateInModal: ChoosingFormat.AUTOIMAGICA });
      navBarStore.setSwtichMode(SwitcherModeType.imagica);
    } else {
      this.previewAppStore.changeCreatingAutoImagicaProject(true);
      try {
        const result = await this.brainClient.betaApi.createProject();
        if (result && result.id) {
          const token = this.authStore.state.brainToken;
          const targetUrl = `${settings.autoImagicaUrl}experiment/${result.id}`;
          await PostMessageUtil.sendTokenToWindow({ token, targetUrl });
          this.previewAppStore.changeCreatingAutoImagicaProject(false);
          this.canelModalView();
        }
      } catch (error) {
        func.messageUtil("Create auot imgica project failed", "error");
        this.previewAppStore.changeCreatingAutoImagicaProject(false);
      }
    }
  }

  // 关闭弹框并返回首页
  canelModalView(): void {
    newOnboardingFlowStore.setOpenOptTempLateModal(false);
    getIt(FotNavigateController).openCreatorHomePage();
    getIt(HomePluginStore).setInitWithCot(false);
  }

  /**
   * 运行第一条边时，添加input和output节点到preview
   * 只执行一次
   */
  private aiAppAddNodeToPreview(edgeId: string): void {
    if (chooseFormatStore.state.chooseFormat !== ChoosingFormat.AIAPP) return;
    chooseFormatStore.setChooseFormat(null);
    this.addInputAndOutputNodeToPreview(edgeId, "saas");
  }

  //  根据edgeId找到对应的input和output node并打开preview，将其添加进去
  private addInputAndOutputNodeToPreview(edgeId: string, type: string): void {
    this.openPreviewPage();
    const { sourceNode, outputNode } = this.findFirstRunInputAndOutputNode(edgeId);
    if (Array.isArray(sourceNode)) {
      const inputIdArr: any[] = [];
      sourceNode.forEach(async item => {
        inputIdArr.push(item.id);
        await this.homeHandleFieldMethods.handleInputField(item, type);
      });
      this.setInputId(inputIdArr);
    } else {
      this.homeHandleFieldMethods.handleInputField(sourceNode);
      this.setInputId((sourceNode as any).id);
    }
    if (GROUPID_REG.test(outputNode?.id || "")) {
      this.creatorPreviewService.clickGroupToPreview(outputNode, type);
    } else {
      this.creatorPreviewService.addIndividual(outputNode, type);
    }
  }

  // 通过运行的边去获取input和output节点
  private findFirstRunInputAndOutputNode(edgeId: string): {
    sourceNode: any[];
    outputNode: any;
  } {
    const edgeNode = this.creatorNodesStore.getNodeById(edgeId);
    const inputNodeIdArr = edgeNode?.data?.flows?.map((item: any) => item.sourceNodeId);
    const outputNodeId = edgeNode?.data?.targetNodeId;
    const inputNodeArr: any[] = [];
    const outputNode = this.creatorNodesStore.getNodeById(outputNodeId);
    inputNodeIdArr?.forEach((inputId: string) => {
      inputNodeArr.push(this.creatorNodesStore.getNodeById(inputId));
    });
    return { sourceNode: inputNodeArr, outputNode };
  }

  /**
   * 第一次运行边时，添加output节点到preview
   * 只执行一次
   */
  private staticWebSiteAddNodeToPreview(edgeId: string): void {
    if (chooseFormatStore.state.chooseFormat !== ChoosingFormat.STATICWEBSITE) return;
    chooseFormatStore.setChooseFormat(null);
    const { outputNode } = this.findFirstRunOutputNode(edgeId);
    this.openPreviewPage();
    if (GROUPID_REG.test(outputNode?.id || "")) {
      this.creatorPreviewService.clickGroupToPreview(outputNode, "saas");
    } else {
      this.creatorPreviewService.addIndividual(outputNode, "saas");
    }
  }

  private aiChatAddNodeToPreview(edgeId: string): void {
    if (chooseFormatStore.state.chooseFormat !== ChoosingFormat.AICHATAGENT) return;
    chooseFormatStore.setChooseFormat(null);
    this.addInputAndOutputNodeToPreview(edgeId, "saas");
  }

  // 通过运行的边去获取input和output节点
  private findFirstRunOutputNode(edgeId: string): {
    outputNode: any;
  } {
    const edgeNode = this.creatorNodesStore.getNodeById(edgeId);
    const outputNodeId = edgeNode?.data?.targetNodeId;
    const outputNode = this.creatorNodesStore.getNodeById(outputNodeId);
    return { outputNode };
  }
}
