"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeStore = void 0;
const Me_1 = require("../api/userTyped/Me");
const StoreBase_1 = require("../cutil/StoreBase");
const Lazyload_1 = require("../cutil/Lazyload");
const JsonUtil_1 = require("../cutil/JsonUtil");
const LangUtil_1 = require("../cutil/LangUtil");
const FeatureAccess_1 = require("../cutil/FeatureAccess");
const FeatureTags_1 = require("../domain/project/FeatureTags");
class MeStore extends StoreBase_1.StoreBase {
    constructor(brainClient, featureAccess = new FeatureAccess_1.FeatureAccess()) {
        super(() => new Me_1.Me());
        this.brainClient = brainClient;
        this.featureAccess = featureAccess;
        this.lazyload = new Lazyload_1.Lazyload(() => {
            if (this.state.id !== undefined) {
                /// 已从fot收到数据，无需自己调api
                return Promise.resolve(this.state);
            }
            return this.brainClient.user.me().then(x => {
                const state = x.data ?? new Me_1.Me();
                this.emit(state);
                return state;
            });
        });
        /**
         * 只需要记录一组数据就行了，避免内存占用过大
         * @private
         */
        this.profileImgUrlCache = {};
    }
    /**
     * 通过Profile_url 动态获取blob url
     */
    async profileImgUrl() {
        const result = await (0, LangUtil_1.tryPromise)(this.get());
        const imgUrl = result.data?.profile?.profile_img_url;
        if (result.data && imgUrl) {
            const cacheBlob = this.profileImgUrlCache[imgUrl];
            if (cacheBlob) {
                return cacheBlob.get();
            }
            const lazyLoad = new Lazyload_1.Lazyload(async () => {
                const resultNew = await (0, LangUtil_1.tryPromise)(this.get());
                const imgUrl = resultNew.data?.profile?.profile_img_url;
                if (imgUrl) {
                    const requesturl = `/api/me/upload/im?url=${imgUrl}`;
                    const url = this.brainClient.user.getBlobUrl(requesturl);
                    return url;
                }
                return "";
            });
            this.profileImgUrlCache = {};
            this.profileImgUrlCache[imgUrl] = lazyLoad;
            return lazyLoad.get();
        }
        return "";
    }
    async get() {
        return this.lazyload.get();
    }
    dirty() {
        this.lazyload.dirty();
        this.emit(new Me_1.Me());
    }
    receive(meData) {
        if (meData == undefined) {
            return;
        }
        const me = JsonUtil_1.JsonUtil.toModelFromType(Me_1.Me, meData);
        if (me === undefined || me.id === undefined) {
            return;
        }
        this.lazyload.dirty();
        this.emit(me);
    }
    /// stock节点，用是否能进行操作
    async hasStockOperationPermission() {
        const me = await this.get();
        const permissions = me.profile?.permissions || [];
        const components = permissions
            .filter(per => {
            if (per.key === "components") {
                return true;
            }
            return false;
        })
            .first();
        if (components == undefined) {
            return false;
        }
        const hasPermission = components.value?.some(element => {
            return element === "brain_studios_stock_purchase";
        }) || false;
        return hasPermission;
    }
    getAllFeatureTags() {
        const disableUploadNodeUnStructured = this.disablePermission("disable_upload_node_unStructured");
        const disableUploadNodeHtml = this.disablePermission("disable_upload_node_html");
        const disableUploadNodeImage = this.disablePermission("disable_upload_node_image");
        const disableViewBuilderToolBoxIntegration = this.disablePermission("disable_view_builder_tool_box_integration");
        const disableGenUI = this.disablePermission("disable_gen_UI");
        const disableCallerBot = this.disablePermission("disable_caller_bot");
        const disableCotOnboarding = this.disablePermission("disable_cot_onboarding");
        const disableManualOnboarding = this.disablePermission("disable_manual_onboarding");
        const disableToolBarPublish = this.disablePermission("disable_toolBar_publish");
        const disableUploadTypesAsInputNode = this.disablePermission("disable_upload_types_as_input_node");
        const disableTriggerManualOnboardingInCanvas = this.disablePermission("disable_trigger_manual_onboarding_in_canvas");
        const disableTriggerCotOnboardingInCanvas = this.disablePermission("disable_trigger_cot_onboarding_in_canvas");
        const disableCotFlowInCanvas = this.disablePermission("disable_cot_flow_in_canvas");
        const disableMobilePage = this.disablePermission("disable_mobile_page");
        const disableMultiRoundChatOnboarding = this.disablePermission("disable_multi_round_chat_onboarding");
        const disableMultiRoundChatOnboardingStream = this.disablePermission("disable_multi_round_chat_onboarding_stream");
        // 暂时改为默认不禁用，等后期正式上线后，再改为默认禁用，使用disablePermission
        const disableImagicaBeta = this.enablePermission("disable_imagica_beta");
        const showEdgeBluePrint = this.enablePermission("disable_edge_blue_print");
        const showDesignSpaceMenu = this.enablePermission("disable_design_space_menu");
        const showSubscriptionFeaturesToggle = this.enablePermission("disable_studios_subscription");
        const showStudiosAgent = this.enablePermission("disable_studios_agent");
        const showProtein = this.enablePermission("disable_studios_protein");
        const showMap = this.enablePermission("disable_studios_map");
        const showUserFeedback = this.enablePermission("disable_user_feedback");
        const showCotUserFeedback = this.enablePermission("disable_cot_user_feedback");
        const showInterviewBot = this.enablePermission("disable_interview_bot");
        const enableSkipEmptyVerification = this.enablePermission("disable_skip_empty_verification");
        const enable_saas_web = this.enablePermission("disable_saas_web");
        const enableFood = this.enablePermission("disable_food");
        const enableMidjourneyV2 = this.enablePermission("disable_studios_midjourney_v2");
        const enableStaticMode = this.enablePermission("disable_studios_static_mode");
        const showExpFeatures = this.enablePermission("disable_studios_exp_features");
        const enableShrinkAndExpand = this.enablePermission("disable_shrinkAndExpand");
        const enableStudiosCustomJsFunctionChatBox = this.enablePermission("disable_studios_customJSFunction_chatBox");
        const enableGlobalFunctionPublish = this.enablePermission("disable_global_function_publish");
        const enableChatAiFlow = this.enablePermission("disable_chat_ai_flow_in_canvas");
        const enablePublishV3 = this.enablePermission("disable_publish_v3");
        const enableGroupCollapse = this.enablePermission("disable_group_collapse");
        const enableCotTemplateV2 = this.enablePermission("disable_studios_cot_template_v2");
        const enableStudiosPaywall = this.enablePermission("disable_studios_paywall");
        const enableGlobalContext = this.enablePermission("disable_global_context", this.state.is_guest);
        const showSuggestionFunctions = this.enablePermission("disable_suggestion_functions", this.state.is_guest);
        const showBuyButton = this.enablePermission("disable_buy_button", this.state.is_guest);
        const enablePreviewOutputV2 = this.enablePermission("disable_previewOutput_v2", this.state.is_guest);
        const enableChatCanvasConnection = this.enablePermission("disable_chat_canvas_connection");
        const _featureTags = {
            disableUploadNodeUnStructured,
            disableUploadNodeHtml,
            disableViewBuilderToolBoxIntegration,
            disableGenUI,
            disableCallerBot,
            disableCotOnboarding,
            disableManualOnboarding,
            disableToolBarPublish,
            disableUploadTypesAsInputNode,
            disableTriggerManualOnboardingInCanvas,
            disableTriggerCotOnboardingInCanvas,
            disableCotFlowInCanvas,
            disableMobilePage,
            showEdgeBluePrint,
            showDesignSpaceMenu,
            showSubscriptionFeaturesToggle,
            showStudiosAgent,
            showProtein,
            showMap,
            showUserFeedback,
            showCotUserFeedback,
            showInterviewBot,
            enableSkipEmptyVerification,
            enable_saas_web,
            enableFood,
            enableMidjourneyV2,
            enableStaticMode,
            showExpFeatures,
            enableShrinkAndExpand,
            enableStudiosCustomJsFunctionChatBox,
            enableGlobalFunctionPublish,
            enableChatAiFlow,
            enablePublishV3,
            enableGroupCollapse,
            enableGlobalContext,
            enableCotTemplateV2,
            enableStudiosPaywall,
            enableChatCanvasConnection,
            showSuggestionFunctions,
            showBuyButton,
            disableMultiRoundChatOnboarding,
            enablePreviewOutputV2,
            disableMultiRoundChatOnboardingStream,
            disableUploadNodeImage,
            disableImagicaBeta,
        };
        const featureTagsClass = new FeatureTags_1.FeatureTags();
        return JsonUtil_1.JsonUtil.toModelFromInstance(featureTagsClass, _featureTags) ?? featureTagsClass;
    }
    disablePermission(featureTag) {
        const tags = this.state.feature_tags ?? [];
        return tags.includes(featureTag);
    }
    enablePermission(featureTag, isGuest) {
        if (isGuest) {
            return false;
        }
        const tags = this.state.feature_tags ?? [];
        return tags.includes(featureTag) === false;
    }
    enableGuestPermission(featureTag, isGuest) {
        if (isGuest) {
            return true;
        }
        const tags = this.state.feature_tags ?? [];
        return tags.includes(featureTag) === false;
    }
    async asyncEnablePermission(featureTag, isGuest) {
        const me = await this.get();
        const tags = me.feature_tags ?? [];
        if (isGuest) {
            return false;
        }
        return tags?.includes(featureTag) === false;
    }
    async hasMidjourneyV2Permission() {
        return this.asyncEnablePermission("disable_studios_midjourney_v2");
    }
    async hasGlobalContextGeneralPermission() {
        const me = await this.get();
        return this.asyncEnablePermission("disable_global_context_general", me.is_guest);
    }
    async hasCustomIndexersPermission() {
        return this.asyncEnablePermission("disable_custom_indexers_response");
    }
    /** 是否自动保存preview上setting、font等数据(BSF-6539) */
    async hasUpdatePreviewDataPermission() {
        return this.asyncEnablePermission("disable_update_preview_data");
    }
    /**
     * custom api v2 权限
     */
    async hasCustomApiV2() {
        return this.asyncEnablePermission("disable_custom_api_v2");
    }
    async hasGlobalContextPermission() {
        const me = await this.get();
        return this.asyncEnablePermission("disable_global_context", me.is_guest);
    }
    async hasCotTemplateV2Permission() {
        return this.asyncEnablePermission("disable_studios_cot_template_v2");
    }
    async hasPaywallPermission() {
        return this.asyncEnablePermission("disable_studios_paywall");
    }
    async hasCorebe() {
        const me = await this.get();
        const tags = me.feature_tags ?? [];
        return tags.includes("disable_no_corebe") || this.featureAccess.can("disable_no_corebe") != undefined;
    }
    async hasMultiRoundOnboardingChatStream() {
        return this.asyncEnablePermission("disable_multi_round_chat_onboarding_stream");
    }
    hasChatCanvasConnection() {
        return this.enablePermission("disable_chat_canvas_connection");
    }
}
exports.MeStore = MeStore;
