import { RelationSetDest } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { ProjectModel } from "../../types";
import { CSSProperties } from "react";
import { SidebarItems } from "../Sidebar/types";
import { ProjectListItem } from "imagica-corekit/dist/base/api/betaProjectTyped/betaProject";

export interface ProjectDropdownItemArgs {
  type: ProjectDropdownItems;
  project: ProjectModel | RelationSetDest;
}

export enum ProjectDropdownItems {
  Duplicate,
  Delete,
}

export class ProjectListViewPorps {
  sidebarItem: SidebarItems = SidebarItems.MyProjects;
  projectsStyle: CSSProperties = {};
  viewOfMyProjects: Record<string, any> = {};
  setShowNavPage: (showNavPage: boolean) => void = () => {};
  didLoadData: () => void = () => {};
  openProject: (value: any) => Promise<void> = async () => {};
  handleClickDuplicate: (type: "function" | "project" | undefined, object: any) => Promise<any> = async () => {};
  handleClickDelete: (type: any, id: any, name: any, object?: any) => Promise<any> = async () => {};
}
export class ProjectListViewBetaPorps {
  sidebarItem: SidebarItems = SidebarItems.MyProjectsBeta;
  projectsStyle: CSSProperties = {};
  viewOfMyProjects: Record<string, any> = {};
  setShowNavPage: (showNavPage: boolean) => void = () => {};
  didLoadData: () => void = () => {};
  openProject: (value: any) => Promise<void> = async () => {};
  handleClickDuplicate: (type: "function" | "project" | undefined, object: any) => Promise<any> = async () => {};
  handleClickDelete: (type: any, id: any, name: any, object?: any) => Promise<any> = async () => {};
}
export interface ProjectProps<T = ProjectModel | RelationSetDest> {
  project: T;
  loading?: boolean;
  onClick?: (project: T) => void;
  onNameChange?: (value: string, project: T) => void;
  onDropdownClick?: (value: ProjectDropdownItemArgs) => void;
}

export interface BetaProjectProps<T = ProjectListItem> {
  project: T;
  loading?: boolean;
  onClick?: (project: T) => void;
  onNameChange?: (value: string, project: T) => void;
  onDropdownClick?: (value: BetaProjectDropdownItemArgs) => void;
}

export interface BetaProjectDropdownItemArgs {
  type: ProjectDropdownItems;
  project: ProjectListItem;
}
