import { useCallback, useMemo } from "react";
import { useSignal } from "@preact/signals-react";
import { getAssetsIcon } from "@views/thinking-layout-editor/utilities";
import { ProjectDropdownItems, BetaProjectProps } from "../type";
import { animated, useSpring } from "@react-spring/web";
import Dropdown, { Triggers } from "../../Dropdown";
import classNames from "classnames";
import Thumbnail from "../Thumbnail";
import Text from "../../Text";
import Icon from "../../Icon";
import dayjs from "dayjs";
import fpcss from "@uiview/views/FotPopover.module.css";
import style from "../ProjectCellStyle.module.scss";

export function BetaCellItem(props: BetaProjectProps): JSX.Element {
  const name = useSignal("");

  const [templateStyle, templateApi] = useSpring(() => ({
    config: { duration: 0 },
    to: [{ transform: "scale(1)" }, { transform: "scale(1)" }],
  }));
  const isDropdownOpen = useSignal(false);

  const updateTime = useMemo(() => {
    return dayjs(props.project.updated).fromNow();
  }, [props.project]);

  const createTime = useMemo(() => {
    return dayjs(props.project.created).fromNow();
  }, [props.project]);

  const handleClick = useCallback(async () => {
    props.onClick?.(props.project);
    templateApi.start({
      config: { duration: 500 },
      to: [{ transform: "scale(0.96)" }, { transform: "scale(1)" }],
    });
  }, [props.project, templateApi, props.onClick]);

  function handleDropdownOpenChange(value: boolean): void {
    isDropdownOpen.value = value;
  }

  const handleDropdownClick = useCallback(
    (type: ProjectDropdownItems) => () => {
      isDropdownOpen.value = false;
      props.onDropdownClick?.({
        type,
        project: props.project,
      });
    },
    [props.project, props.onDropdownClick]
  );

  const trigger = useMemo<Triggers>(
    () => (isDropdownOpen.value ? Triggers.Click : Triggers.Focus),
    [isDropdownOpen.value]
  );

  const onStopEvent = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // 阻止 dropdown 里面内容点击冒泡，防止点击事件触发上层的 handleClick
    e.stopPropagation();
  }, []);

  return (
    <animated.div
      className={classNames(style.cellContent, isDropdownOpen.value ? style.cellSelect : null)}
      style={templateStyle}
      onClick={handleClick}
      onContextMenu={event => {
        event.preventDefault();
        isDropdownOpen.value = true;
      }}
    >
      <div className={style.template__image}>
        <Thumbnail height={80} />
      </div>
      <div className={style.title}>{name.value}</div>
      <div className={style.title}>{updateTime}</div>
      <div className={style.title}>{createTime}</div>
      <Dropdown
        isOpen={isDropdownOpen.value}
        trigger={trigger}
        placement="bottom"
        className={style.templateToolTipsBox}
        showArrow={true}
        overlayClassName={fpcss.projectcell}
        content={
          <div className={style.dropDown_content} onClick={onStopEvent}>
            <div className={style.dropdown__item}>
              {/* <div className={style.dropdownItem} onClick={handleDropdownClick(ProjectDropdownItems.Duplicate)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("copy.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Duplicate</Text>
                </div>
              </div> */}
              <div className={style.dropdownItem} onClick={handleDropdownClick(ProjectDropdownItems.Delete)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("bin.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Delete</Text>
                </div>
              </div>
            </div>
          </div>
        }
        onOpenChange={handleDropdownOpenChange}
      >
        <div className={style.rightIcon}></div>
      </Dropdown>
    </animated.div>
  );
}
