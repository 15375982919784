import { CopyOutlined } from "@ant-design/icons";
import { get } from "lodash";
import React, { FC } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactMarkdown from "react-markdown";
import func from "@uikit/func";
import styles from "./ChatBox.module.css";

type MarkdownProps = {
  markdown: string;
};

const Markdown: FC<MarkdownProps> = React.memo(({ markdown }) => {
  return (
    <ReactMarkdown
      children={markdown}
      components={{
        pre: ({ children }) => (
          <pre>
            <CopyToClipboard
              text={get(children, "0.props.children.0", "")}
              onCopy={() => {
                func.customMsg({ content: "Copied Successfully", type: "success" });
              }}
            >
              <div className={styles.copy}>
                <CopyOutlined />
              </div>
            </CopyToClipboard>
            {children}
          </pre>
        ),
      }}
    />
  );
});

export default Markdown;
