"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostMessageUtil = exports.HandleRedirectOptions = exports.PostMessageOptions = void 0;
const Settings_1 = require("../../base/kernel/Settings");
class PostMessageOptions {
    constructor(token, targetUrl) {
        this.token = token;
        this.targetUrl = targetUrl;
    }
}
exports.PostMessageOptions = PostMessageOptions;
class HandleRedirectOptions {
    constructor(token, currentUrl) {
        this.token = token;
        this.currentUrl = currentUrl;
    }
}
exports.HandleRedirectOptions = HandleRedirectOptions;
class PostMessageUtil {
    static setRedirectUrl(url) {
        localStorage.setItem(this.REDIRECT_KEY, url);
    }
    static getRedirectUrl() {
        return localStorage.getItem(this.REDIRECT_KEY);
    }
    static clearRedirectUrl() {
        localStorage.removeItem(this.REDIRECT_KEY);
    }
    static async handleRedirectWithToken(options) {
        try {
            const { token, currentUrl } = options;
            // 优先从 URL 获取
            const url = new URL(currentUrl);
            const searchParams = new URLSearchParams(url.hash.split("?")[1]);
            const redirect = searchParams.get("redirect") || this.getRedirectUrl();
            if (redirect) {
                const decodedUrl = decodeURIComponent(redirect);
                await PostMessageUtil.sendTokenToWindow({ token, targetUrl: decodedUrl });
                // 清理 redirect URL
                this.clearRedirectUrl();
                return true;
            }
            return false;
        }
        catch (e) {
            console.error("Handle redirect error:", e);
            return false;
        }
    }
    static async sendTokenToWindow(options) {
        const { token, targetUrl = Settings_1.settings.autoImagicaUrl } = options;
        try {
            const url = new URL(targetUrl);
            // 将 token 添加到目标 URL
            url.searchParams.set("token", token);
            // 打开新窗口并直接跳转
            window.open(url.toString(), "_self");
        }
        catch (e) {
            console.error("Send token error:", e);
        }
    }
}
exports.PostMessageUtil = PostMessageUtil;
PostMessageUtil.REDIRECT_KEY = "REDIRECT_URL";
