import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import Dropdown, { Triggers } from "../../Dropdown";
import { ProjectDropdownItems, BetaProjectProps } from "../type";
import { callback, getAssetsIcon } from "../../../utilities";
import { animated, useSpring } from "@react-spring/web";
import { FocusEvent } from "react";
import Icon from "../../Icon";
import dayjs from "dayjs";
import Text from "../../Text";
import Thumbnail from "../Thumbnail";
import Loader from "../../Loader";
import style from "../style.module.scss";

const ProjectItem: FC<BetaProjectProps> = ({
  project,
  loading = false,
  onClick = callback,
  onDropdownClick = callback,
}) => {
  const [templateStyle, templateApi] = useSpring(() => ({}));
  const [name, setName] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    try {
      const newName = project.metadata.name;
      setName(newName);
    } catch (error) {
      setName("");
      console.error(error);
    }
  }, [project]);

  const handleDropdownOpenChange = useCallback((visible: boolean) => {
    setIsDropdownOpen(visible);
  }, []);

  const handleDropdownClick = useCallback(
    (type: ProjectDropdownItems) => () => {
      setIsDropdownOpen(false);
      onDropdownClick({
        type,
        project,
      });
    },
    [project, onDropdownClick]
  );

  const handleTextClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  const datetime = useMemo(() => {
    return dayjs(project.updated).fromNow();
  }, [project]);

  const handleContextMenu = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setIsDropdownOpen(true);
    return false;
  }, []);

  const handleClick = useCallback(async () => {
    onClick(project);
    templateApi.start({
      config: { duration: 1000 },
      to: [{ transform: "scale(0.9)" }, { transform: "scale(1)" }],
    });
  }, [project, templateApi, onClick]);

  const handleFocus = useCallback((event: FocusEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDropdownOpen(false);
  }, []);

  const trigger = useMemo<Triggers>(() => (isDropdownOpen ? Triggers.Click : Triggers.Focus), [isDropdownOpen]);

  return (
    <div style={{ width: "100%" }}>
      {/* {loading && (
        <div className={style.loading}>
          <Loader full />
        </div>
      )} */}
      <Dropdown
        isOpen={isDropdownOpen}
        trigger={trigger}
        placement="bottom"
        className={style.templateToolTipsBox}
        content={
          <div className={style.dropdown}>
            <div className={style.dropdown__item}>
              {/* <div className={style.dropdownItem} onClick={handleDropdownClick(ProjectDropdownItems.Duplicate)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("copy.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Duplicate</Text>
                </div>
              </div> */}
              <div className={style.dropdownItem} onClick={handleDropdownClick(ProjectDropdownItems.Delete)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("bin.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Delete</Text>
                </div>
              </div>
            </div>
          </div>
        }
        onOpenChange={handleDropdownOpenChange}
      >
        <animated.div
          className={style.template}
          style={templateStyle}
          onClick={handleClick}
          onFocus={handleFocus}
          onContextMenu={handleContextMenu}
        >
          {loading && (
            <div className={style.loading}>
              <Loader full />
            </div>
          )}
          <div className={style.template__image}>
            <Thumbnail />
          </div>
          <div className={style.template__description}>
            <div className={style.templateDescription}>
              <div className={style.templateDescription__text} onClick={handleTextClick}>
                <>{name ?? "Untitled"}</>
              </div>
              <div className={style.templateDescription__datetime}>Edited {datetime}</div>
            </div>
          </div>
        </animated.div>
      </Dropdown>
    </div>
  );
};

export default ProjectItem;
