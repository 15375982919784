import { Signal } from "@preact/signals-react";
import { getIt } from "@uikit/getIt";
import { ApiStatuses } from "@views/thinking-layout-editor/types";
import { ProjectTemplateBetaStore } from "imagica-corekit/dist/cases/store/ProjectTemplateBetaStore";
import func from "@uikit/func";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { logEvent } from "@amplitude/analytics-browser";
import { NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { BetaProjectDropdownItemArgs, ProjectDropdownItems, ProjectListViewBetaPorps } from "./type";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { ProjectListItem } from "imagica-corekit/dist/base/api/betaProjectTyped/betaProject";
import { PostMessageUtil } from "imagica-corekit/dist/cases/util/postMessageUtil";

export class ProjectListBetaState {
  loadingOfProject: Signal<ProjectListItem | undefined> = new Signal(undefined);
  screenLoader: Signal<boolean> = new Signal(false);
  apiStatusOfProjects: Signal<ApiStatuses> = new Signal(ApiStatuses.Idle);
  showlist: Signal<boolean> = new Signal(false);
}
export class ProjectListBetaBloc {
  store = getIt(ProjectTemplateBetaStore);
  meStore = getIt(MeStore);
  navBarStore = getIt(NavBarStore);
  brainClient = getIt(BrainClient);
  chatOnboardingStore = getIt(ChatOnboardingStore);
  authStore = getIt(FotAuthStore);

  state = new ProjectListBetaState();

  onInitData?: () => void = () => {};
  private props?: ProjectListViewBetaPorps;

  constructor(onInitData?: () => void) {
    this.onInitData = onInitData;
  }

  async initData(): Promise<void> {
    this.state.screenLoader.value = true;
    this.state.apiStatusOfProjects.value = ApiStatuses.Pending;
    try {
      await this.store.init();
      this.state.apiStatusOfProjects.value = ApiStatuses.Fulfilled;
    } catch (error) {
      this.state.apiStatusOfProjects.value = ApiStatuses.Rejected;
      func.customMsg({
        content: "Something went wrong",
        type: "error",
      });
    } finally {
      this.state.screenLoader.value = false;
      if (this.onInitData) {
        this.onInitData();
      }
    }
  }

  public changeListView(value: boolean): void {
    this.state.showlist.value = value;
  }

  async nextPage(): Promise<void> {
    try {
      await this.store.nextPage();
    } catch (error) {
      func.customMsg({
        content: "Something went wrong",
        type: "error",
      });
    }
  }

  deleteProject(item: ProjectListItem): void {
    this.store.deletelProjectById(item.id ?? "");
  }

  duplicateProject(item: ProjectListItem): void {
    this.store.addProject(item);
  }

  async sortByRefresh(sortBy: string): Promise<void> {
    this.state.apiStatusOfProjects.value = ApiStatuses.Pending;
    try {
      await this.store.init(sortBy);
      this.state.apiStatusOfProjects.value = ApiStatuses.Fulfilled;
    } catch (error) {
      this.state.apiStatusOfProjects.value = ApiStatuses.Rejected;
      func.customMsg({
        content: "Something went wrong",
        type: "error",
      });
    } finally {
      this.state.screenLoader.value = false;
    }
  }

  setProps(props: ProjectListViewBetaPorps): void {
    this.props = props;
  }

  isProjectLoading = (project: ProjectListItem): boolean => {
    return !!this.state.loadingOfProject.value && this.state.loadingOfProject.value.id === project.id;
  };

  async checkNext(doubleCheck: boolean): Promise<void> {
    const clientHeight = document.getElementById("project_list_beta")?.clientHeight ?? 0;
    const windowH = document.getElementById("homeContainerBeta")?.clientHeight ?? window.innerHeight;
    const minheight = windowH - 50 - 230 - 20;
    if (clientHeight < minheight) {
      await this.nextPage();
      if (doubleCheck) {
        await this.checkNext(false);
      }
    }
  }

  async handleProjectClick(project: ProjectListItem): Promise<void> {
    if (this.state.loadingOfProject.value) {
      return;
    }

    this.state.loadingOfProject.value = project;

    try {
      const token = this.authStore.state.brainToken;
      const targetUrl = `${settings.autoImagicaUrl}experiment/${project.id}`;
      await PostMessageUtil.sendTokenToWindow({ token, targetUrl });
    } finally {
      this.state.loadingOfProject.value = undefined;
    }
  }

  async handleProjectDropdownClick(args: BetaProjectDropdownItemArgs): Promise<void> {
    if (args.type === ProjectDropdownItems.Duplicate) {
      const destinationProject = await this.props?.handleClickDuplicate("project", args.project);
      logEvent("duplicate_Function", {
        source_id: args.project.id,
        destination_id: destinationProject.uuid,
      });
      func.messageUtil("Successfully duplicated project", "success");
      this.duplicateProject(destinationProject);
    }
    if (args.type === ProjectDropdownItems.Delete) {
      const project: any = args.project;
      try {
        await this.brainClient.betaApi.deleteProject(project.id);
        logEvent("delete_project_confirmed", args.project);
        func.messageUtil("The project has been deleted successfully", "success");
        this.deleteProject(args.project);
      } catch (error) {
        func.messageUtil("Delete project failed", "error");
      }
    }
  }
}
