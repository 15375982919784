"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectTemplateBetaStore = exports.ProjectTemplateBetaStoreState = void 0;
const StoreBase_1 = require("../../base/cutil/StoreBase");
const ProjectTemplateUtil_1 = require("../util/ProjectTemplateUtil");
class ProjectTemplateBetaStoreState {
    constructor() {
        this.projects = [];
        this.size = 20;
        this.isGetAllProject = false;
        this.sortBy = "-updated_at";
        this.filterAutoImagic = false;
    }
    copyWith(param) {
        const newState = new ProjectTemplateBetaStoreState();
        newState.projects = param.projects ?? this.projects;
        newState.size = param.size ?? this.size;
        newState.isGetAllProject = param.isGetAllProject ?? this.isGetAllProject;
        newState.sortBy = param.sortBy ?? this.sortBy;
        newState.filterAutoImagic = param.filterAutoImagic ?? this.filterAutoImagic;
        return newState;
    }
}
exports.ProjectTemplateBetaStoreState = ProjectTemplateBetaStoreState;
class ProjectTemplateBetaStore extends StoreBase_1.StoreBase {
    constructor(brainClient) {
        super(() => new ProjectTemplateBetaStoreState());
        this.brainClient = brainClient;
    }
    async filterAutoImagic(filterAutoImagic = false) {
        this.emit(this.state.copyWith({
            projects: [],
            isGetAllProject: false,
            filterAutoImagic: filterAutoImagic,
        }));
        return await this.searchProject(1, this.state.sortBy);
    }
    async init(sortBy = "-updated_at") {
        this.emit(this.state.copyWith({
            projects: [],
            isGetAllProject: false,
            sortBy: sortBy,
        }));
        return await this.searchProject(1, this.state.sortBy);
    }
    async nextPage() {
        if (this.state.isGetAllProject) {
            return Promise.resolve(this.state.projects);
        }
        const page = Math.ceil(this.state.projects.length / this.state.size) + 1;
        return await this.searchProject(page, this.state.sortBy);
    }
    async searchProject(page, sortBy) {
        try {
            const res = await this.brainClient.betaApi.getProjectList({
                pageIndex: page,
                pageSize: this.state.size,
                sortProperties: sortBy === "-updated_at" ? "updated" : "created",
            });
            const filterArr = ProjectTemplateUtil_1.ProjectTemplateUtil.projectDeduplication(this.state.projects, res?.items || []);
            // const sliceArr = filterArr.slice(0, 3);
            const newArr = [...this.state.projects, ...filterArr];
            const newState = { projects: newArr };
            // todo: 这里正常逻辑是不需要感叹号，暂时加上测试用
            // if (!isBlank(res?.items)) {
            //   newState.isGetAllProject = true;
            // }
            newState.isGetAllProject = true;
            this.emit(this.state.copyWith(newState));
            return newArr;
        }
        catch (error) {
            if (error?.message?.includes("That page contains no results")) {
                this.emit(this.state.copyWith({ isGetAllProject: true }));
                return [];
            }
            else {
                return Promise.reject(error);
            }
        }
    }
    addProject(project) {
        this.emit(this.state.copyWith({ projects: [project, ...this.state.projects] }));
    }
    deletelProjectById(id) {
        const newState = this.state.copyWith({});
        newState.projects = newState.projects.filter(x => x.id !== id);
        this.emit(newState);
    }
}
exports.ProjectTemplateBetaStore = ProjectTemplateBetaStore;
