"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BetaProjectApi = void 0;
const Settings_1 = require("../kernel/Settings");
class BetaProjectApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    async getProjectList(params) {
        const result = await this.httpRequest.post(`${Settings_1.settings.autoImagicaApiUrl}/project/find`, {}, { ...params, sortDirection: "DESC", pageIndex: params.pageIndex - 1 }, {
            "Content-Type": "application/json",
        });
        return result.data;
    }
    async deleteProject(id) {
        const result = await this.httpRequest.post(`${Settings_1.settings.autoImagicaApiUrl}/project/delete`, {}, { id }, {
            "Content-Type": "application/json",
        });
        return result.data;
    }
    async createProject() {
        const result = await this.httpRequest.post(`${Settings_1.settings.autoImagicaApiUrl}/project/create`, { metadata: { name: "Untitled" } }, {}, {
            "Content-Type": "application/json",
        });
        return result.data;
    }
}
exports.BetaProjectApi = BetaProjectApi;
