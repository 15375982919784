"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBasic = void 0;
const AuthStore_1 = require("../base/store/AuthStore");
const ProjectStore_1 = require("../base/store/ProjectStore");
const MessageCenter_1 = require("../base/kernel/MessageCenter");
const RestClient_1 = require("../base/cutil/RestClient");
const BrainClient_1 = require("../base/api/BrainClient");
const MeStore_1 = require("../base/store/MeStore");
const PersonalFunctionStore_1 = require("../base/store/PersonalFunctionStore");
const FunctionV2Store_1 = require("../base/store/FunctionV2Store");
const GlobalFunctionStore_1 = require("../base/store/GlobalFunctionStore");
const LocationManager_1 = require("../base/port/LocationManager");
const TopicService_1 = require("../base/storyV2/service/TopicService");
const CustomApiTransfromService_1 = require("../base/storyV2/response/customApiHandler/CustomApiTransfromService");
const FunctionRegistry_1 = require("../base/storyV2/function/FunctionRegistry");
const AdapterRegistry_1 = require("../base/storyV2/adapter/AdapterRegistry");
const StorageManager_1 = require("../base/port/StorageManager");
const StoryManager_1 = require("../base/storyV2/domain/StoryManager");
const ToolPieceStore_1 = require("../base/store/ToolPieceStore");
const BlueprintsStore_1 = require("../base/store/BlueprintsStore");
const FotClient_1 = require("../base/api/FotClient");
const FotAuthStore_1 = require("../base/store/FotAuthStore");
const CommonHeaderService_1 = require("../base/storyV2/service/CommonHeaderService");
const AiAppThemeStore_1 = require("../cases/theme/appTheme/AiAppThemeStore");
const ChatAppThemeStore_1 = require("../cases/theme/chatTheme/ChatAppThemeStore");
const SubscriptionStore_1 = require("../cases/store/SubscriptionStore");
const PreferencesStore_1 = require("../base/store/PreferencesStore");
const WebConfigStore_1 = require("../cases/webconfig/WebConfigStore");
const PublishSubscriptionStore_1 = require("../cases/store/PublishSubscriptionStore");
const GeneratePromptService_1 = require("../base/storyV2/service/GeneratePromptService");
const WebsocketService_1 = require("../base/storyV2/service/WebsocketService");
const Analytics_1 = require("../base/port/Analytics");
const ResponseHandlerRegistry_1 = require("../base/storyV2/response/handler/ResponseHandlerRegistry");
const ProjectTemplateStore_1 = require("../cases/store/ProjectTemplateStore");
const FeatureAccess_1 = require("../base/cutil/FeatureAccess");
const ChatOnboarding_1 = require("../cases/chatOnboarding/ChatOnboarding");
const UpDownLoadService_1 = require("../cases/UpDownLoadService/UpDownLoadService");
const SocketRestClient_1 = require("../base/cutil/SocketRestClient");
const UploadService_1 = require("../base/service/UploadService");
const ResponseDistribution_1 = require("../base/storyV2/response/ResponseDistribution");
const CardDataSeriviceRegistry_1 = require("../cases/cardDataSearch/dataSerivce/CardDataSeriviceRegistry");
const CardDataManager_1 = require("../cases/cardDataSearch/CardDataManager");
const ViewbuilderStore_1 = require("../cases/store/ViewbuilderStore");
const BluePrintDataService_1 = require("../cases/cardDataSearch/dataSerivce/BluePrintDataService");
const ViewbuilderImgStore_1 = require("../cases/store/ViewbuilderImgStore");
const AblyStore_1 = require("../base/store/AblyStore");
const ProjectTemplateBetaStore_1 = require("../cases/store/ProjectTemplateBetaStore");
class RegisterBasic {
    static configure(container, params) {
        const it = (serviceIdentifier) => {
            return container.get(serviceIdentifier);
        };
        if (!container.isBound(Analytics_1.Analytics)) {
            container.bind(Analytics_1.Analytics).toConstantValue(new Analytics_1.Analytics());
        }
        container.bind(FeatureAccess_1.FeatureAccess).toConstantValue(new FeatureAccess_1.FeatureAccess(params.defaultStorageManager, params.location));
        container.bind(StorageManager_1.StorageManager).toConstantValue(params.defaultStorageManager);
        container.bind(ProjectStore_1.ProjectStore).toConstantValue(new ProjectStore_1.ProjectStore());
        container.bind(AblyStore_1.AblyStore).toConstantValue(new AblyStore_1.AblyStore());
        container.bind(AuthStore_1.AuthStore).toConstantValue(new AuthStore_1.AuthStore(params.cookieStorageManager));
        container.bind(RestClient_1.RestAbortManager).toConstantValue(new RestClient_1.RestAbortManager());
        container
            .bind(BrainClient_1.BrainClient)
            .toConstantValue(new BrainClient_1.BrainClient(it(AuthStore_1.AuthStore), it(RestClient_1.RestAbortManager), it(ProjectStore_1.ProjectStore), it(FeatureAccess_1.FeatureAccess), params.getRestAdapter ? params.getRestAdapter(container) : undefined, params.getGraphqlAdapter ? params.getGraphqlAdapter(container) : undefined, params.getGlobalAdapter ? params.getGlobalAdapter(container) : undefined, params.getCorebeAdapter ? params.getCorebeAdapter(container) : undefined));
        container.bind(MeStore_1.MeStore).toConstantValue(new MeStore_1.MeStore(it(BrainClient_1.BrainClient), it(FeatureAccess_1.FeatureAccess)));
        container.bind(PersonalFunctionStore_1.PersonalFunctionStore).toConstantValue(new PersonalFunctionStore_1.PersonalFunctionStore(it(BrainClient_1.BrainClient)));
        container.bind(FunctionV2Store_1.FunctionV2Store).toConstantValue(new FunctionV2Store_1.FunctionV2Store(it(BrainClient_1.BrainClient)));
        container.bind(GlobalFunctionStore_1.GlobalFunctionStore).toConstantValue(new GlobalFunctionStore_1.GlobalFunctionStore(it(BrainClient_1.BrainClient)));
        container.bind(LocationManager_1.LocationManager).toConstantValue(params.locationManager);
        container.bind(TopicService_1.TopicService).toConstantValue(new TopicService_1.TopicService(it(MeStore_1.MeStore), it(AuthStore_1.AuthStore)));
        container.bind(MessageCenter_1.MessageCenter).toConstantValue(new MessageCenter_1.MessageCenter(it(AuthStore_1.AuthStore), it(MeStore_1.MeStore), it(AblyStore_1.AblyStore)));
        container.bind(WebsocketService_1.WebsocketService).toConstantValue(new WebsocketService_1.WebsocketService(it(MessageCenter_1.MessageCenter)));
        container.bind(SocketRestClient_1.SocketRestClient).toConstantValue(new SocketRestClient_1.SocketRestClient(it(TopicService_1.TopicService), it(WebsocketService_1.WebsocketService)));
        container.bind(CommonHeaderService_1.CommonHeaderService).toConstantValue(new CommonHeaderService_1.CommonHeaderService(it(AuthStore_1.AuthStore), it(ProjectStore_1.ProjectStore)));
        container.bind(CustomApiTransfromService_1.CustomApiTransfromService).toConstantValue(new CustomApiTransfromService_1.CustomApiTransfromService(it(BrainClient_1.BrainClient)));
        container.bind(UpDownLoadService_1.UpDownLoadService).toConstantValue(new UpDownLoadService_1.UpDownLoadService(it(BrainClient_1.BrainClient)));
        container.bind(GeneratePromptService_1.GeneratePromptService).toConstantValue(new GeneratePromptService_1.GeneratePromptService(it(BrainClient_1.BrainClient)));
        container
            .bind(FunctionRegistry_1.FunctionRegistry)
            .toConstantValue(new FunctionRegistry_1.FunctionRegistry(it(BrainClient_1.BrainClient), it(GlobalFunctionStore_1.GlobalFunctionStore), it(PersonalFunctionStore_1.PersonalFunctionStore), it(AuthStore_1.AuthStore), it(FunctionV2Store_1.FunctionV2Store), it(MeStore_1.MeStore), it(SocketRestClient_1.SocketRestClient)));
        container
            .bind(AdapterRegistry_1.AdapterRegistry)
            .toConstantValue(new AdapterRegistry_1.AdapterRegistry(it(PersonalFunctionStore_1.PersonalFunctionStore), it(GlobalFunctionStore_1.GlobalFunctionStore), it(FunctionV2Store_1.FunctionV2Store), it(MeStore_1.MeStore)));
        container.bind(ResponseHandlerRegistry_1.ResponseHandlerRegistry).toConstantValue(new ResponseHandlerRegistry_1.ResponseHandlerRegistry());
        container.bind(ResponseDistribution_1.ResponseDistribution).toConstantValue(new ResponseDistribution_1.ResponseDistribution(it(ResponseHandlerRegistry_1.ResponseHandlerRegistry)));
        container
            .bind(StoryManager_1.StoryManager)
            .toConstantValue(new StoryManager_1.StoryManager(it(AdapterRegistry_1.AdapterRegistry), it(FunctionRegistry_1.FunctionRegistry), it(RestClient_1.RestAbortManager), it(MessageCenter_1.MessageCenter), it(ResponseDistribution_1.ResponseDistribution), it(GeneratePromptService_1.GeneratePromptService), it(Analytics_1.Analytics)));
        container.bind(ToolPieceStore_1.ToolPieceStore).toConstantValue(new ToolPieceStore_1.ToolPieceStore(it(BrainClient_1.BrainClient)));
        container.bind(BlueprintsStore_1.BlueprintsStore).toConstantValue(new BlueprintsStore_1.BlueprintsStore(it(BrainClient_1.BrainClient)));
        container.bind(FotAuthStore_1.FotAuthStore).toConstantValue(new FotAuthStore_1.FotAuthStore(params.cookieStorageManager, it(AuthStore_1.AuthStore)));
        container.bind(FotClient_1.FotClient).toConstantValue(new FotClient_1.FotClient(it(FotAuthStore_1.FotAuthStore), it(ProjectStore_1.ProjectStore), it(LocationManager_1.LocationManager)));
        container.bind(AiAppThemeStore_1.AiAppThemeStore).toConstantValue(new AiAppThemeStore_1.AiAppThemeStore());
        container.bind(ChatAppThemeStore_1.ChatAppThemeStore).toConstantValue(new ChatAppThemeStore_1.ChatAppThemeStore());
        container.bind(PreferencesStore_1.PreferencesStore).toConstantValue(new PreferencesStore_1.PreferencesStore(it(BrainClient_1.BrainClient)));
        container.bind(WebConfigStore_1.WebConfigStore).toConstantValue(new WebConfigStore_1.WebConfigStore(it(FotClient_1.FotClient)));
        container
            .bind(SubscriptionStore_1.SubscriptionStore)
            .toConstantValue(new SubscriptionStore_1.SubscriptionStore(it(BrainClient_1.BrainClient), it(PreferencesStore_1.PreferencesStore), it(WebConfigStore_1.WebConfigStore)));
        container.bind(PublishSubscriptionStore_1.PublishSubscriptionStore).toConstantValue(new PublishSubscriptionStore_1.PublishSubscriptionStore(it(BrainClient_1.BrainClient)));
        container.bind(CardDataSeriviceRegistry_1.CardDataServiceRegistry).toConstantValue(new CardDataSeriviceRegistry_1.CardDataServiceRegistry());
        container
            .bind(CardDataManager_1.CardDataManager)
            .toConstantValue(new CardDataManager_1.CardDataManager(it(CardDataSeriviceRegistry_1.CardDataServiceRegistry), it(BrainClient_1.BrainClient), new BluePrintDataService_1.BluePrintDataService(it(BrainClient_1.BrainClient), it(SocketRestClient_1.SocketRestClient))));
        container.bind(ViewbuilderStore_1.ViewbuilderStore).toConstantValue(new ViewbuilderStore_1.ViewbuilderStore(it(BrainClient_1.BrainClient), it(CardDataManager_1.CardDataManager)));
        container.bind(ViewbuilderImgStore_1.ViewbuilderImgStore).toConstantValue(new ViewbuilderImgStore_1.ViewbuilderImgStore());
        container.bind(ProjectTemplateStore_1.ProjectTemplateStore).toConstantValue(new ProjectTemplateStore_1.ProjectTemplateStore(it(BrainClient_1.BrainClient)));
        container.bind(ProjectTemplateBetaStore_1.ProjectTemplateBetaStore).toConstantValue(new ProjectTemplateBetaStore_1.ProjectTemplateBetaStore(it(BrainClient_1.BrainClient)));
        container.bind(ChatOnboarding_1.ChatOnboardingStore).toConstantValue(new ChatOnboarding_1.ChatOnboardingStore());
        container.bind(UploadService_1.UploadService).toConstantValue(new UploadService_1.UploadService(it(BrainClient_1.BrainClient)));
    }
}
exports.RegisterBasic = RegisterBasic;
