"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrainClient = void 0;
const BrainAdapter_1 = require("../api/BrainAdapter");
const GlobalAdapter_1 = require("./GlobalAdapter");
const GlobalApi_1 = require("../api/GlobalApi");
const GraphqlApi_1 = require("../api/GraphqlApi");
const OpenAiApi_1 = require("../api/OpenAiApi");
const StockApi_1 = require("../api/StockApi");
const UserApi_1 = require("../api/UserApi");
const RetrievalApi_1 = require("../api/RetrievalApi");
const GraphqlClient_1 = require("../cutil/GraphqlClient");
const RestClient_1 = require("../cutil/RestClient");
const StreamClient_1 = require("../cutil/StreamClient");
const BluePrintApi_1 = require("./BluePrintApi");
const UploadApi_1 = require("../api/UploadApi");
const GlobalClient_1 = require("../cutil/GlobalClient");
const PayWallApi_1 = require("./PayWallApi");
const DomainApi_1 = require("./DomainApi");
const SaaSMonetizationApi_1 = require("./SaaSMonetizationApi");
const CorebeApi_1 = require("../api/CorebeApi");
const CorebeAdapter_1 = require("../api/CorebeAdapter");
const CorebeStreamClient_1 = require("../api/CorebeStreamClient");
const FileApi_1 = require("../api/FileApi");
const XMLHttpRequestClient_1 = require("../util/XMLHttpRequestClient");
const PubsubApi_1 = require("../api/PubsubApi");
const SaveApi_1 = require("../../cases/saveService/SaveApi");
const PayApi_1 = require("../api/PayApi");
const FlightApi_1 = require("../api/FlightApi");
const BetaProjectApi_1 = require("./BetaProjectApi");
class BrainClient {
    constructor(authStore, abortManager = undefined, projectStore, featureAccess, restAdapter = undefined, graphqlAdapter = undefined, globalAdapter = undefined, corebeAdapter = undefined) {
        this.authStore = authStore;
        this.abortManager = abortManager;
        this.projectStore = projectStore;
        this.featureAccess = featureAccess;
        this.restAdapter = restAdapter ?? new BrainAdapter_1.BrainAdapter(authStore, projectStore);
        const _graphqlAdapter = graphqlAdapter ?? new BrainAdapter_1.BrainGraphqlAdapter(authStore, projectStore);
        const _globalAdapter = globalAdapter ?? new GlobalAdapter_1.GlobalAdapter();
        this.restClient = new RestClient_1.RestClient(this.restAdapter, abortManager);
        this.xhrClient = new XMLHttpRequestClient_1.XMLHttpRequestClient(this.restAdapter, abortManager);
        this.graphqlClient = new GraphqlClient_1.GraphqlClient(_graphqlAdapter, abortManager);
        this.streamClient = new StreamClient_1.StreamClient(this.restAdapter, abortManager);
        this.globalClient = new GlobalClient_1.GlobalClient(_globalAdapter, abortManager);
        // 请求参数需要使用restClient这一套，但返回结果需要像globalClient返回GlobalResponse。
        this.customDataClient = new GlobalClient_1.GlobalClient(this.restAdapter, abortManager);
        const _corebeAdapter = corebeAdapter ?? new CorebeAdapter_1.CorebeAdapter(authStore, projectStore, featureAccess);
        this.corebeStreamClient = new CorebeStreamClient_1.CorebeStreamClient(_corebeAdapter, abortManager);
        this.corebeClient = new RestClient_1.RestClient(_corebeAdapter, abortManager);
    }
    get graphql() {
        return new GraphqlApi_1.GraphqlApi(this.graphqlClient);
    }
    get user() {
        return new UserApi_1.UserApi(this.restClient, this.customDataClient);
    }
    get openAi() {
        return new OpenAiApi_1.OpenAiApi(this.restClient, this.streamClient, this.xhrClient);
    }
    get global() {
        return new GlobalApi_1.GlobalApi(this.globalClient);
    }
    get stock() {
        return new StockApi_1.StockApi(this.restClient);
    }
    get bluePrint() {
        return new BluePrintApi_1.BluePrintApi(this.restClient);
    }
    get retrieval() {
        return new RetrievalApi_1.RetrievalApi(this.restClient);
    }
    get upload() {
        return new UploadApi_1.UploadApi(this.restClient, this.restAdapter);
    }
    get payWall() {
        return new PayWallApi_1.PayWallApi(this.restClient);
    }
    get domain() {
        return new DomainApi_1.DomainApi(this.restClient);
    }
    get saaSMonetization() {
        return new SaaSMonetizationApi_1.SaaSMonetizationApi(this.restClient);
    }
    get corebe() {
        return new CorebeApi_1.CorebeApi(this.corebeClient, this.corebeStreamClient);
    }
    get pubsub() {
        return new PubsubApi_1.PubsubApi(this.restClient);
    }
    get file() {
        return new FileApi_1.FileApi(this.restClient, this.globalClient);
    }
    get saveApi() {
        return new SaveApi_1.SaveApi(this.graphqlClient);
    }
    get payApi() {
        return new PayApi_1.PayApi(this.restClient);
    }
    get flightApi() {
        return new FlightApi_1.FlightApi(this.restClient);
    }
    get betaApi() {
        return new BetaProjectApi_1.BetaProjectApi(this.restClient);
    }
}
exports.BrainClient = BrainClient;
