import InfiniteScroll from "react-infinite-scroll-component";
import ProjectItem from "./ProjectItem";
import { animated } from "@react-spring/web";
import { JSX, useCallback, useEffect, useRef } from "react";
import ApiProgress from "../ApiProgress";
import style from "./ProjectListViewStyle.module.scss";
import { useCreation } from "ahooks";
import { ProjectListViewBloc } from "./ProjectListViewBloc";
import { logEvent } from "@uikit/service/amplitude";
import func from "@uikit/func";
import { ProjectDropdownItemArgs, ProjectDropdownItems, ProjectListViewPorps } from "./type";
import Loader from "../Loader";
import { RelationSetDest } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { ProjectTemplateStore } from "imagica-corekit/dist/cases/store/ProjectTemplateStore";
import { ChatOnboardingState, ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { NavBarStore, SwitcherModeType } from "@uiview/views/navBar/NavBarStore";
import { ProjectCellItem } from "./ProjectCellItem";
import { SortByView, UIModel } from "./SortBy/SortByView";
import { ListButton } from "./Buttons/ListButton";
import { GridButton } from "./Buttons/GridButton";
import { ListViewHeader } from "./ListViewHeader/ListViewHeader";
import { OpenProjectLoading } from "./Mobile/Loading/OpenProjectLoading";
import { SidebarItems } from "../Sidebar/types";

export function ProjectListView(props: ProjectListViewPorps): JSX.Element {
  const bloc = useCreation(() => new ProjectListViewBloc(props.didLoadData), []);

  const ref = useRef<HTMLDivElement>(null);

  const projectState = useImagincStore(getIt(ProjectTemplateStore)).value;
  const navBarStore = getIt(NavBarStore);

  const isProjectLoading = useCallback(
    (project: RelationSetDest) => !!bloc.loadingOfProject.value && bloc.loadingOfProject.value.id === project.id,
    [bloc.loadingOfProject.value]
  );

  async function checkNext(doubleCheck: boolean): Promise<void> {
    const clientHeight = document.getElementById("project_list")?.clientHeight ?? 0;
    const windowH = document.getElementById("homeContainer")?.clientHeight ?? window.innerHeight;
    const minheight = windowH - 50 - 230 - 20;
    // console.log("checkNext", clientHeight, windowH ,minheight);
    if (clientHeight < minheight) {
      await bloc.nextPage();
      if (doubleCheck) {
        checkNext(false);
      }
    }
  }

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        checkNext(true);
      }, 1000);
    }
  }, [ref.current]);

  useEffect(() => {
    bloc.initData();
  }, []);

  const handleSelectedProject = useCallback(
    async (value: any) => {
      try {
        await props.openProject(value);
        props.setShowNavPage(false);
      } catch (error) {
        bloc.loadingOfProject.value = undefined;
        console.log("error", error);
      }
    },
    [props.openProject]
  );

  const handleProjectClick = useCallback(
    async (project: RelationSetDest) => {
      if (bloc.loadingOfProject.value) {
        return;
      }
      // 进入project时，赋值一次chatOnboardingStore
      const chatOnboardingStore = getIt(ChatOnboardingStore);
      chatOnboardingStore.setState(project?.attributesV2?.multiRoundChatData || new ChatOnboardingState());
      if (project?.attributesV2?.multiRoundChatData?.isOpenChat) {
        navBarStore.setSwtichMode(SwitcherModeType.imagica);
      }
      bloc.loadingOfProject.value = project;
      await handleSelectedProject(project);
      bloc.loadingOfProject.value = undefined;
    },
    [handleSelectedProject]
  );

  const handleProjectDropdownClick = useCallback(
    async (args: ProjectDropdownItemArgs) => {
      if (args.type === ProjectDropdownItems.Duplicate) {
        const destinationProject = await props.handleClickDuplicate("project", args.project);
        logEvent("duplicate_Function", {
          source_id: args.project.attrIndexer ?? args.project.uuid,
          destination_id: destinationProject.uuid,
        });
        func.messageUtil("Successfully duplicated project", "success");
        /// TODO
        bloc.duplicateProject(destinationProject);
      }
      if (args.type === ProjectDropdownItems.Delete) {
        const project: any = args.project;
        const isOk = await props.handleClickDelete(
          "project",
          project.relationId,
          project.attributesV2?.name || "Untitled",
          project
        );
        logEvent("delete_project_confirmed", args.project);
        if (isOk) {
          func.messageUtil("The project has been deleted successfully", "success");
          bloc.deleteProject(args.project);
        }
      }
    },
    [props.handleClickDuplicate, props.handleClickDelete]
  );

  if (props.sidebarItem !== SidebarItems.MyProjects) {
    return <></>;
  }
  return bloc.screenLoader.value ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader full={true} screenLoader={bloc.screenLoader.value} />
    </div>
  ) : (
    <animated.div
      ref={ref}
      className={style.view}
      style={{
        ...props.projectsStyle,
        ...props.viewOfMyProjects,
      }}
      id="project_list"
    >
      <div className={style.view__title}>
        My Projects
        <div className={style.searchbar}>
          {/* <Input
            onPressEnter={(e) => { bloc.searchProjects(e) }}
            prefix={<SearchOutlined />}
            className={style.input}
            placeholder="Search Projects"
          /> */}
          <SortByView
            model={UIModel.web}
            onSelectSortBy={sortType => {
              bloc.sortByRefresh(sortType.value);
            }}
          />
          <ListButton
            isHeightLight={bloc.showlist.value}
            onClickBtn={() => {
              bloc.changeListView(true);
            }}
          />
          <GridButton
            isHeightLight={!bloc.showlist.value}
            onClickBtn={() => {
              bloc.changeListView(false);
            }}
          />
        </div>
      </div>
      <InfiniteScroll
        className={bloc.showlist.value ? style.view__list : style.view__content}
        style={{ overflow: undefined }}
        dataLength={projectState.projects.length}
        loader={null}
        hasMore={true}
        next={() => {
          bloc.nextPage();
        }}
        scrollableTarget="homeContainer"
      >
        {bloc.showlist.value ? (
          <>
            <ListViewHeader />
            <ApiProgress status={bloc.apiStatusOfProjects.value} full>
              {projectState.projects.map(item => (
                <ProjectCellItem
                  key={item.id}
                  project={item}
                  loading={isProjectLoading(item)}
                  onClick={handleProjectClick}
                  onNameChange={() => {}}
                  onDropdownClick={handleProjectDropdownClick}
                />
              ))}
            </ApiProgress>
          </>
        ) : (
          <ApiProgress status={bloc.apiStatusOfProjects.value} full>
            {projectState.projects.map(item => (
              <ProjectItem
                key={item.id}
                project={item}
                loading={isProjectLoading(item)}
                onClick={handleProjectClick}
                onNameChange={() => {}}
                onDropdownClick={handleProjectDropdownClick}
              />
            ))}
          </ApiProgress>
        )}
      </InfiniteScroll>
      <div className={style.loader_more}>
        {projectState.isGetAllProject ? (
          <span>No more data</span>
        ) : (
          <Spin style={{ fontSize: 30 }} indicator={<LoadingOutlined spin />} />
        )}
      </div>
      {bloc.loadingOfProject.value && <OpenProjectLoading />}
    </animated.div>
  );
}
