import { JSX } from "react";
import classNames from "classnames";
import Modal from "../Modal";
import style from "./CreateProjectModal.module.scss";
import cls from "classnames";
import { matchMobile } from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { Spin } from "antd";

export type ProjectType = "autoImagica" | "thinkingCanvas";

type CreateProjectModalProps = {
  isShow: boolean;
  onCancelView: () => void;
  onClickItem: (type: ProjectType) => void;
};

export function CreateProjectModal(props: CreateProjectModalProps): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const previewAppStore = getIt(PreviewAppStore);
  const homeStoreState = useStore(homeStore).value;
  const previewAppStoreState = useStore(previewAppStore).value;
  const featureTags = homeStoreState.featureTags;

  return (
    <Modal wrapClassName={style.cover} mask={false} isOpen={props.isShow}>
      <div className={classNames(style.content, { [style.contentMobile]: isMobile && featureTags.disableMobilePage })}>
        <div className={style.title}>Create new project with</div>
        <div className={style.closeBtn} onClick={() => props.onCancelView()}>
          <img src="https://dopniceu5am9m.cloudfront.net/static/assets/230705/ACAEB5Cross.svg" alt="" />
        </div>
        <Spin spinning={previewAppStoreState.creatingAutoImagicaProject}>
          <div
            className={style.item_view}
            onClick={() => {
              props.onClickItem("autoImagica");
            }}
          >
            <div className={style.left_view}>
              <div>
                <div className={style.label_title}>Auto Imagica</div>
                <div className={style.label_info}>Chat with AI builder to turn your idea into reality</div>
              </div>
              <div className={style.label_tip}>
                <div className={style.label_tip_box}>Recommended for new users</div>
              </div>
            </div>
            <div className={style.right_view}>
              <img src="https://dopniceu5am9m.cloudfront.net/static/assets/230705/auto_imagica.png" alt="" />
            </div>
          </div>
        </Spin>
        <div
          className={classNames(style.item_view, {
            [style.item_view_disable]: isMobile && featureTags.disableMobilePage,
          })}
          onClick={() => {
            props.onClickItem("thinkingCanvas");
          }}
        >
          <div className={style.left_view}>
            <div className={style.label_title}>Thinking Canvas</div>
            <div className={style.label_info}>Build a project with our powerful nodes-based Thinking canvas</div>
            <div className={style.label_tip}>
              <div className={cls(style.label_tip_box, style.label_tip_box_blue)}>For more advanced users</div>
            </div>
          </div>
          <div className={style.right_view}>
            <img src="https://dopniceu5am9m.cloudfront.net/static/assets/230705/thinking_canvas.png" alt="" />
          </div>
        </div>
      </div>
    </Modal>
  );
}
