import InfiniteScroll from "react-infinite-scroll-component";
import BetaItem from "./BetaItem/BetaItem";
import { animated } from "@react-spring/web";
import { JSX, useEffect, useRef } from "react";
import ApiProgress from "../ApiProgress";
import style from "./ProjectListViewBetaStyle.module.scss";
import { useCreation } from "ahooks";
import { ProjectListBetaBloc } from "./ProjectListViewBetaBloc";
import { ProjectListViewBetaPorps } from "./type";
import Loader from "../Loader";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { ProjectTemplateBetaStore } from "imagica-corekit/dist/cases/store/ProjectTemplateBetaStore";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BetaCellItem } from "./BetaItem/BetaCellItem";
import { SortByView, UIModel } from "./SortBy/SortByView";
import { ListButton } from "./Buttons/ListButton";
import { GridButton } from "./Buttons/GridButton";
import { ListViewHeader } from "./ListViewHeader/ListViewHeader";
import { OpenProjectLoading } from "./Mobile/Loading/OpenProjectLoading";
import { SidebarItems } from "../Sidebar/types";

export function ProjectListBetaView(props: ProjectListViewBetaPorps): JSX.Element {
  const bloc = useCreation(() => new ProjectListBetaBloc(props.didLoadData), []);
  const ref = useRef<HTMLDivElement>(null);
  const projectState = useImagincStore(getIt(ProjectTemplateBetaStore)).value;

  bloc.setProps(props);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        bloc.checkNext(true);
      }, 1000);
    }
  }, [bloc, ref.current]);

  useEffect(() => {
    bloc.initData();
  }, []);

  if (props.sidebarItem !== SidebarItems.MyProjectsBeta) {
    return <></>;
  }

  return bloc.state.screenLoader.value ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader full={true} screenLoader={bloc.state.screenLoader.value} />
    </div>
  ) : (
    <animated.div
      ref={ref}
      className={style.view}
      style={{
        ...props.projectsStyle,
        ...props.viewOfMyProjects,
      }}
      id="project_list_beta"
    >
      <div className={style.view__title}>
        My Projects Beta
        <div className={style.searchbar}>
          <SortByView
            model={UIModel.web}
            onSelectSortBy={sortType => {
              bloc.sortByRefresh(sortType.value);
            }}
          />
          <ListButton isHeightLight={bloc.state.showlist.value} onClickBtn={() => bloc.changeListView(true)} />
          <GridButton isHeightLight={!bloc.state.showlist.value} onClickBtn={() => bloc.changeListView(false)} />
        </div>
      </div>
      <InfiniteScroll
        className={bloc.state.showlist.value ? style.view__list : style.view__content}
        style={{ overflow: undefined }}
        dataLength={projectState.projects.length}
        loader={null}
        hasMore={true}
        next={() => bloc.nextPage()}
        scrollableTarget="homeContainerBeta"
      >
        {bloc.state.showlist.value ? (
          <>
            <ListViewHeader />
            <ApiProgress status={bloc.state.apiStatusOfProjects.value} full>
              {projectState.projects.map(item => (
                <BetaCellItem
                  key={item.id}
                  project={item}
                  loading={bloc.isProjectLoading(item)}
                  onClick={() => bloc.handleProjectClick(item)}
                  onDropdownClick={args => bloc.handleProjectDropdownClick(args)}
                />
              ))}
            </ApiProgress>
          </>
        ) : (
          <ApiProgress status={bloc.state.apiStatusOfProjects.value} full>
            {projectState.projects.map(item => (
              <BetaItem
                key={item.id}
                project={item}
                loading={bloc.isProjectLoading(item)}
                onClick={() => bloc.handleProjectClick(item)}
                onDropdownClick={args => bloc.handleProjectDropdownClick(args)}
              />
            ))}
          </ApiProgress>
        )}
      </InfiniteScroll>
      <div className={style.loader_more}>
        {projectState.isGetAllProject ? (
          <span>No more data</span>
        ) : (
          <Spin style={{ fontSize: 30 }} indicator={<LoadingOutlined spin />} />
        )}
      </div>
      {bloc.state.loadingOfProject.value && <OpenProjectLoading />}
    </animated.div>
  );
}
