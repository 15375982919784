import { useSignal } from "@preact/signals-react";
import { useEffect } from "react";
import { IMAGICA_NEW_PROJECT_ONBOARDING } from "@views/thinking-layout-editor/constants";
import { ChoosingFormatBloc } from "../ChoosingFormatBloc";
import { NEW_EDGE_REG, GROUPID_REG } from "@views/thinking-layout-editor/constants";
import func, { matchMobile } from "@uikit/func";
import { logEvent } from "@uikit/service/amplitude";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { chooseFormatStore } from "@uikit/store/ChooseFormatStore";
import { PreferencesStore } from "imagica-corekit/dist/base/store/PreferencesStore";
import {
  CreateProjectModal,
  ProjectType,
} from "@views/thinking-layout-editor/Home/CreateProjectModal/CreateProjectModal";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { CreateProjectService } from "@uikit/service/CreateProjectService";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { HomeHandleFieldMethods } from "@uikit/service/HomeHandleFieldMethods";

export function TemplateModal(): JSX.Element | null {
  const isMobile = matchMobile();
  const creatorAISaasStore = getIt(CreatorAISaasStore);
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const featureTags = homeStoreState.featureTags;
  const preferencesStore = getIt(PreferencesStore);
  const creatorPreviewService = getIt(CreatorPreviewService);
  const fotSaveService = getIt(FotSaveService);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const previewAppStore = getIt(PreviewAppStore);
  const previewAppStoreState = useStore(previewAppStore).value;
  const createProjectService = getIt(CreateProjectService);
  const homeHandleFieldMethods = getIt(HomeHandleFieldMethods);
  const { enableStaticMode, disableCotFlowInCanvas, enableChatAiFlow } = homeStoreState.featureTags;

  const newOnboardingFlowState = useImagicaStore(newOnboardingFlowStore).value;

  const bloc = new ChoosingFormatBloc({
    creatorNodesStore,
    createProjectService,
    creatorPreviewService,
    openPreviewPage: previewAppStore.openPreviewPage,
    setInputId: creatorAISaasStore.setInputId,
    previewAppStore: previewAppStore,
    homeHandleFieldMethods,
  });

  //  监听第一条边运行时，把node添加进preview
  useEffect(() => {
    if (chooseFormatStore.state.chooseFormat === null) return;
    const nodes = creatorNodesStore.getNodes();
    const edges = creatorEdgesStore.getEdges();
    // 没有node, 没有线，或者没有边 不继续
    if (func.isEmpty(nodes) || func.isEmpty(edges) || nodes.every(x => !NEW_EDGE_REG.test(x.id))) return;
    const edgeNodes = nodes.filter(node => NEW_EDGE_REG.test(node.id));
    edgeNodes.some(edge => {
      const outputNode: any = nodes.find(node => node.id === edge?.data?.targetNodeId);
      if (
        (!func.isEmpty(outputNode?.data?.textAreaValue) || GROUPID_REG.test(outputNode?.id)) &&
        edge?.data?.lineParam?.loading === false
      ) {
        bloc.addNodeToPreview(edge.id);
        return true;
      }
      return false;
    });
    // eslint-disable-next-line
  }, [creatorNodesStore.state.nodes, creatorEdgesStore.state.edges]);

  const showNextTime = useSignal(false);

  const postPreferenceJson = async (): Promise<void> => {
    if (!showNextTime.value) return;
    const value = JSON.stringify({
      dontShowAgain: showNextTime.value,
    });
    await preferencesStore.set(IMAGICA_NEW_PROJECT_ONBOARDING, value);
  };

  // 重置勾选
  /* useEffect(() => {
    const data = {key: IMAGICA_NEW_PROJECT_ONBOARDING, value: JSON.stringify({
      dontShowAgain: false,
    })}
    api.postWithError(`/api/users/me/preferences.json`, data)
  },[]) */

  // 选择模板时触发对应的App的自动添加的监听

  useEffect(() => {
    if (newOnboardingFlowState.selectedFlowItem?.value === "thinkingCanvas") {
      // 为什么thinkingCanvas放在这里处理，是因为thinkingCanvas存在在不同地方触发
      bloc.createThankingCanvas();
      homeStore.setIsClickAddNewProject(false);
    }
    // eslint-disable-next-line
  }, [newOnboardingFlowState.selectedFlowItem?.value]);

  // 选择模板
  // 禁用模板
  const handleDisableTemplate = (values: string[]): void => {
    newOnboardingFlowStore.setTemplateList(
      newOnboardingFlowStore.state.templateList.map(x => {
        const newList = x.list.map(y => {
          if (values.includes(y.value)) {
            return {
              ...y,
              disabled: true,
            };
          }
          return y;
        });
        return {
          ...x,
          list: newList,
        };
      })
    );
  };

  // 直接关闭等同于选择thinkingCanvas

  useEffect(() => {
    // 根据feature tag禁用template
    const disabledTemplates = [
      enableStaticMode ? "" : "staticWeb",
      !disableCotFlowInCanvas ? "" : "autoImagica",
      enableChatAiFlow ? "" : "aiChat",
    ];
    handleDisableTemplate(disabledTemplates);
    // 重置选中状态
    newOnboardingFlowStore.setTemplateList(
      newOnboardingFlowStore.state.templateList.map(x => {
        const newList = x.list.map(y => {
          return {
            ...y,
            selected: false,
          };
        });
        return {
          ...x,
          list: newList,
        };
      })
    );
  }, []);

  const handleSelectProjectType = (type: ProjectType): void => {
    if (previewAppStoreState.creatingAutoImagicaProject) {
      return;
    }
    if (type === "autoImagica") {
      bloc.createAutoImagicaV2();
      return;
    } else {
      bloc.createThankingCanvas();
    }
    const tempItem = newOnboardingFlowStore.state.templateListV2.find(item => {
      return (item.value = type);
    });
    if (tempItem) {
      newOnboardingFlowStore.setSelectedFlowItem(
        Object.assign({}, tempItem, {
          selected: true,
        })
      );
      newOnboardingFlowStore.setOpenOptTempLateModal(false);
      postPreferenceJson();
      logEvent("entrypoint_format", { format: tempItem.value, event: tempItem.eventName });
    }
    homeStore.setIsClickAddNewProject(false);
    fotSaveService.handleSave({ background: true, backgroundCreateRelation: false });
  };

  if (isMobile && featureTags.disableMobilePage) {
    return null;
  }

  return (
    <CreateProjectModal
      isShow={newOnboardingFlowState.openOptTempLateModal}
      onCancelView={() => {
        bloc.canelModalView();
      }}
      onClickItem={type => {
        handleSelectProjectType(type);
      }}
    />
  );
  // return !disableMultiRoundChatOnboarding ? (
  //   <CreateProjectModal
  //     isShow={newOnboardingFlowState.openOptTempLateModal}
  //     onCancelView={() => {
  //       newOnboardingFlowStore.setOpenOptTempLateModal(false);
  //       navigate("/editor");
  //       showNavPageStore.setShowNavPage(true);
  //       getIt(HomePluginStore).setInitWithCot(false);
  //     }}
  //     onClickItem={type => {
  //       handleSelectProjectType(type);
  //     }}
  //   />
  // ) : (
  //   <Modal
  //     title=""
  //     maskClosable={false}
  //     keyboard={false}
  //     open={newOnboardingFlowState.openOptTempLateModal}
  //     onCancel={onClickCancel}
  //     wrapClassName={style["project-template-modal-box"]}
  //     closeIcon={<img src={`${settings.S3CDN}${settings.viewAssetsPath}ACAEB5Cross.svg`} width="20" height="20" />}
  //     footer={[]}
  //     centered={true}
  //   >
  //     <div className={style.templateBox}>
  //       <header className={style.template}>
  //         {newOnboardingFlowState.templateList.map((item, index) => {
  //           return (
  //             <div key={index} className={item.list.length > 1 ? style.templateItemFlexWrap : style.templateItemFlex}>
  //               <p className={style.templateTitle}>{item.typeTitle}</p>
  //               <div className={style.templateList}>
  //                 {item.list.map((x, idx) => {
  //                   return (
  //                     <div key={idx} className={`${style[item.list.length > 1 ? "itemWidth" : "itemNewWidth"]}`}>
  //                       <section
  //                         className={`${style["templateItem"]} ${style[x.disabled === true ? "templateDisable" : ""]} ${
  //                           x.selected ? style.selectedTemplateItem : ""
  //                         }`}
  //                         onClick={event => handleSelectTemplate(event, x)}
  //                       >
  //                         <div style={{ flex: 1 }}>
  //                           <h5>{x.title}</h5>
  //                           <p className={style.templateDescribe}>
  //                             {x.description.map((j, jIdx) => {
  //                               return <span key={j + jIdx}>{j}</span>;
  //                             })}
  //                           </p>
  //                         </div>
  //                         <div className={style.templatePic}>
  //                           <img src={`${settings.S3CDN}${settings.viewAssetsPath}${x.image}`}></img>
  //                         </div>
  //                       </section>
  //                     </div>
  //                   );
  //                 })}
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </header>

  //       <footer className={style.templateTipsBox}>
  //         <label
  //           onClick={() => {
  //             logEvent("select_donot_show_screen");
  //             showNextTime.value = !showNextTime.value;
  //           }}
  //         >
  //           <input
  //             className={style.templateCheckBox}
  //             type="checkbox"
  //             checked={showNextTime.value}
  //             onChange={() => {}}
  //           />
  //         </label>

  //         <span
  //           onClick={() => {
  //             logEvent("select_donot_show_screen");
  //             showNextTime.value = !showNextTime.value;
  //           }}
  //         >
  //           Don't show this screen next time
  //         </span>
  //       </footer>
  //     </div>
  //   </Modal>
  // );
}
